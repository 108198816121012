<template>
  <v-row class="ma-5">
    <Overlay :loading="loading" />
    <!-- Create Profissional Dialog -->
    <v-dialog v-model="createProfissional" persistent max-width="900px">
      <v-card>
        <v-col>
          <v-card-title>
            <span> Cadastrar novo administrador</span>
          </v-card-title>
          <v-card-subtitle>
            <p>Formulário de cadastro para novo administrador</p>
          </v-card-subtitle>
          <v-container>
            <v-form
              ref="createProfessionalForm"
              v-model="formValidate"
              lazy-validation
            >
              <v-row>
                <v-col class="pb-0" cols="12" sm="12" md="6">
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      Nome <span class="red--text"> * </span>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="formData.name"
                        @keypress="blockNumber($event)"
                        clearable
                        outlined
                        placeholder="Nome completo"
                        counter="100"
                        required
                        :rules="[(v) => !!v || 'Nome é obrigatório']"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col class="pb-0" cols="12" sm="12" md="3">
                  <v-dialog
                    ref="birthDateDialog"
                    v-model="birthDateDialog"
                    :return-value.sync="formData.birth_date"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-row>
                        <v-col cols="12" class="pr-0 pb-0">
                          Data de nascimento
                          <span class="red--text">*</span>
                        </v-col>
                        <v-col cols="12" class="">
                          <v-text-field
                            v-model="formData.birth_date"
                            placeholder="00/00/0000"
                            clearable
                            outlined
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            required
                            :rules="[
                              (v) => !!v || 'Data de nascimento é obrigatório',
                            ]"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </template>
                    <v-date-picker
                      :max="date"
                      v-model="formData.birth_date"
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="birthDateDialog = false"
                      >
                        Cancelar
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.birthDateDialog.save(formData.birth_date)"
                      >
                        Selecionar
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col class="pb-0" cols="12" sm="12" md="3">
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      Sexo <span class="red--text"> * </span>
                    </v-col>
                    <v-col cols="12">
                      <v-select
                        :items="sexos"
                        v-model="formData.sex"
                        clearable
                        outlined
                        placeholder="Selecione"
                        required
                        :rules="[(v) => !!v || 'Sexo é obrigatório']"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col class="pb-0" cols="12" sm="12" md="6">
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      E-mail <span class="red--text"> * </span>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="formData.email"
                        clearable
                        outlined
                        placeholder="example@gmail.com"
                        required
                        :rules="[(v) => !!v || 'E-mail é obrigatório']"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col class="pb-0" cols="12" sm="12" md="6">
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      Telefone <span class="red--text"> * </span>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="formData.phone"
                        clearable
                        outlined
                        placeholder="(00) 0 0000-0000"
                        required
                        v-mask="'(##) # ####-####'"
                        :rules="[(v) => !!v || 'Telefone é obrigatório']"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col class="pb-0" cols="12" sm="12" md="6">
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      CPF <span class="red--text"> * </span>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="formData.cpf"
                        clearable
                        outlined
                        placeholder="000.000.000-00"
                        required
                        v-mask="'###.###.###-##'"
                        :rules="[(v) => !!v || 'CPF é obrigatório']"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col class="pb-0" cols="12" sm="12" md="6">
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      RG <span class="red--text"> * </span>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="formData.rg"
                        clearable
                        outlined
                        placeholder="0.000.000"
                        v-mask="'#.###.###'"
                        required
                        :rules="[(v) => !!v || 'RG é obrigatório']"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" class="d-flex justify-end">
                  <v-btn elevation="0" class="mr-5" @click="closeCreateDialog()">
                    fechar
                  </v-btn>
                  <v-btn
                    outlined 
                    color="primary"
                    @click="$route.params.id ? onUpdate() : onCreate()"
                    :disabled="!formValidate"
                    :loading="loading"
                  >
                    {{ $route.params.id ? "Salvar" : "Cadastrar" }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-col>
      </v-card>
    </v-dialog>
    <!-- body -->
    <v-col cols="12" class="pa-5" sm="8">
      <v-row>
        <v-col cols="12">
          <v-row no-gutters>
            <span class="text-h4">
              <!-- Olá, {{ currentUser.name || "Não definido" }} -->
              Administradores
            </span>
          </v-row>
          <v-row no-gutters>
            <span class="caption font-weight-light pt-0 pb-0">
              <!-- {{currentDate}} -->
              Lista de administradores cadastrados no sistema
            </span>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" sm="12" md="4" class="d-flex justify-end mt-5">
      <v-card
        outlined
        rounded="0"
        width="80%"
        height="6vh"
        class="d-flex"
        @click="createProfissional = true"
      >
        <v-col cols="12" class="d-flex justify-center">
          <v-icon class="pr-2">mdi-plus-circle-outline</v-icon>
          <span class="register">novo cadastro</span>
        </v-col>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card class="elevation-0 rounded-0" outlined>
        <v-data-table
          :headers="headers"
          :items="professionals"
          :loading="!professionals"
          item-key="id"
        >
          <template v-slot:[`item.menu`]="{ item }">
            <div>
              <v-menu offset-y>
                <template v-slot:activator="{ attrs, on }">
                  <v-btn v-bind="attrs" v-on="on" text>
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-if="item.role_id === 'Ativo'"
                    link
                    @click="disableProfissional(item.id)"
                  >
                    <v-list-item-title style="color: red">
                      Desativar profissional
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-else
                    link
                    @click="turnProfessionalActive(item.id)"
                  >
                    <v-list-item-title style="color: green">
                      Ativar profissional
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import Overlay from "../../../components/Overlay/Overlay.vue";
// import Excel from "../../../components/Excel/Excel.vue";

export default {
  components: {
    Overlay,
    // Excel,
  },
  data: () => ({
    createProfissional: false,
    date: " ",
    formValidate: true,
    birthDateDialog: false,
    formData: {
      name: null,
      cpf: null,
      rg: null,
      birthDate: null,
      sex: null,
      email: null,
      role_id: 9,
      password: 123456,
    },
    sexos: [
      {
        text: "Feminino",
        value: "0",
      },
      {
        text: "Masculino",
        value: "1",
      },
    ],
    e: "",
    newProfessional: {
      name: "",
      birth_date: "",
      email: "",
      cpf: "",
      rg: "",
      role_id: 9,
      phone: "",
      sex: "",
      password: "",
    },
    sex: [
      { text: "Feminino", value: "0" },
      { text: "Masculino", value: "1" },
    ],
    search: null,
    reason: null,
    loading: false,
    valid: true,
    professionals: [],
    deleteProfessionalId: null,
    openDeleteProfessional: false,
    json_fields_patient: {
      Nome: {
        field: "name",
        callback: (e) => {
          let textFormatted = "";

          for (var i = 0; i < e.split(" ").length; i++) {
            textFormatted += `${e.split(" ")[i]}\n`;
          }

          return textFormatted;
        },
      },
      CPF: "cpf",
      "E-mail": {
        field: "email",
        callback: (e) => {
          let textFormatted = "";

          for (var i = 0; i < e.split(" ").length; i++) {
            textFormatted += `${e.split(" ")[i]}\n`;
          }

          return textFormatted;
        },
      },
      Criação: {
        field: "created_at",
        callback: (e) => {
          let textFormatted = "";

          for (var i = 0; i < e.split(" ").length; i++) {
            textFormatted += `${e.split(" ")[i]}\n`;
          }

          return textFormatted;
        },
      },
      deleteProfissional: null,
    },
    turn_professional_active: {
      role_id: 9,
    },
    turn_professional_disabled: {
      role_id: 16,
    },
  }),
  mounted() {
    this.showProfessionals();
    this.saveCurrentDate();

    if (this.$route.params.id) {
      if (!this.$route.params.data) return this.showProfessional();

      this.$route.params.data.sex =
        this.$route.params.data.sex == "male" ? "1" : "0";
      this.formData = this.$route.params.data;
    }
  },
  methods: {
    async turnProfessionalActive(id) {
      try {
        await this.$axios.put("users/" + id, this.turn_professional_active);

        this.showProfessionals();

        await this.$swal({
          toast: false,
          position: "center",
          showConfirmButton: true,
          icon: "success",
          title: "MIS",
          text: "Status do profissional alterado com sucesso",
        });
      } catch (error) {
        console.log("reactivate patient error: ", error);
        this.$swal({
          toast: false,
          showConfirmButton: true,
          icon: "error",
          title: "MIS",
          text: "Ocorreu um error ao tentar alterar o status desse profissional",
        });
      }
    },
    async disableProfissional(id) {
      try {
        await this.$axios.put("users/" + id, this.turn_professional_disabled);

        this.showProfessionals();

        await this.$swal({
          toast: false,
          position: "center",
          showConfirmButton: true,
          icon: "success",
          title: "MIS",
          text: "Status do profissional alterado com sucesso",
        });
      } catch (error) {
        console.log("reactivate patient error: ", error);
        this.$swal({
          toast: false,
          showConfirmButton: true,
          icon: "error",
          title: "MIS",
          text: "Ocorreu um error ao tentar alterar o status deste profissional",
        });
      }
    },
    saveCurrentDate() {
      var date = new Date();
      var day = String(date.getDate()).padStart(2, "0");
      var mounth = String(date.getMonth() + 1).padStart(2, "0");
      var year = date.getFullYear();
      this.date = year + "-" + mounth + "-" + day;
      // console.log(this.date);
    },
    closeCreateDialog() {
      this.createProfissional = false;
    },
    blockNumber(e) {
      if (e.keyCode > 47 && e.keyCode < 58) e.preventDefault();
    },
    async onCreate() {
      this.loading = true;

      if (!this.$refs.createProfessionalForm.validate()) {
        this.loading = false;
        return;
      }

      this.formData.sex = Number(this.formData.sex);

      try {
        await this.$axios
          .post("users", {
            user: this.formData,
          })
          .catch((res) => {
            if (!res.response.data) return;

            const errors = res.response.data;
            const errorMessages = [];

            for (const i in errors) {
              errorMessages.push(`"${i[0].toUpperCase() + i.substr(1)}"`);
            }

            if (res.response.status === 422) {
              this.loading = false;
              this.$swal({
                position: "center",
                showConfirmButton: true,
                icon: "error",
                title: "MIS",
                text:
                  `${errors.length > 1 ? "Campos " : "Campo"} em uso: ` +
                  errorMessages.join(", "),
              });
              this.loading = false;
              throw new Error("Error 422");
            }
          });

        this.$swal({
          position: "center",
          showConfirmButton: true,
          icon: "success",
          title: "MIS",
          text: "Profissional criado com sucesso.",
        });

        this.closeCreateDialog();
        this.formData = {
          name: null,
          cpf: null,
          rg: null,
          birthDate: null,
          sex: null,
          email: null,
          role_id: 9,
          password: 123456,
        };
        this.$refs.createProfessionalForm.resetValidation();
        this.loading = false;
        this.showProfessionals();
      } catch (error) {
        const msg = error.response.error;
        this.loading = false;
        this.showProfessionals();
        // console.log("Create professional error: ", error);
        this.$swal({
          position: "center",
          showConfirmButton: true,
          icon: "error",
          title: "MIS",
          text: "Ocorreu um error ao tentar criar o profissional",
          msg,
        });
      }
    },
    async showProfessionals() {
      try {
        this.loading = true;
        const response = await this.$axios("users", {
          params: {
            managerList: true,
          },
        });
        console.log(response)
        this.professionals = response.data.map((el) => {
          return {
            id: el.id,
            name: el.name,
            created_at: this.formatLastUpdate(el.created_at),
            role_id: el.role_id == 9 ? "Ativo" : "Inativo",
          };
        });

        this.loading = false;
      } catch (error) {
        const msg = error.response.error;

        this.loading = false;
        // console.log("Show professionals error: ", error);
        await this.$swal({
          position: "center",
          showConfirmButton: true,
          icon: "error",
          title: "MIS",
          text: "Ocorreu um erro ao tentar carregar os profissionais." + msg,
        });
      }
    },
    onDeleteDialog(id) {
      this.deleteProfessionalId = id;
      this.openDeleteProfessional = true;
    },
    filterOnlyText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toLowerCase().toString().indexOf(search.toLowerCase()) !== -1
      );
    },
    formatLastUpdate(date) {
      if (!date) return "";

      const day = date.slice(8, 10);
      const month = date.slice(5, 7);
      const year = date.slice(0, 4);
      const time = date.slice(11, 19);

      return `${day}/${month}/${year} às ${time}`;
    },
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.authentication.currentUser,
    }),
    headers() {
      return [
        { text: "Administrador", value: "name", width: 500, class: "teste" },
        {
          text: "Data de admissão no sistema",
          value: "created_at",
          width: 220,
        },
        // { text: "Ação", value: "menu", width: 75, align: "center" },
      ];
    },
  },
};
</script>

<style>
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
.register {
  display: inline-flex;
  align-items: center;
}
.icon {
  display: inline-flex;
  align-items: center;
}
</style>
